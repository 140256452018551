// @ts-ignore
import baac from './baac.svg'
// @ts-ignore
import bay from './bay.svg'
// @ts-ignore
import ttb from './ttb.svg'
// @ts-ignore
import hsbc from './hsbc.svg'
// @ts-ignore
import tisco from './tisco.svg'
// @ts-ignore
import citi from './citi.svg'
// @ts-ignore
import gsb from './gsb.svg'
// @ts-ignore
import scb from './scb.svg'
// @ts-ignore
import icbc from './icbc.svg'
// @ts-ignore
import lhb from './lhb.svg'
// @ts-ignore
import bbl from './bbl.svg'
// @ts-ignore
import kk from './kk.svg'
// @ts-ignore
import sc from './sc.svg'
// @ts-ignore
import ibank from './ibank.svg'
// @ts-ignore
import tcrb from './tcrb.svg'
// @ts-ignore
import uob from './uob.svg'
// @ts-ignore
import kbank from './kbank.svg'
// @ts-ignore
import cimb from './cimb.svg'
// @ts-ignore
import ghb from './ghb.svg'
// @ts-ignore
import ktb from './ktb.svg'

const OmiseSVG: { [key: string]: string | null | undefined } = {
  baac: baac,
  bay: bay,
  ttb: ttb,
  hsbc: hsbc,
  tisco: tisco,
  citi: citi,
  gsb: gsb,
  scb: scb,
  icbc: icbc,
  lhb: lhb,
  bbl: bbl,
  kk: kk,
  sc: sc,
  ibank: ibank,
  tcrb: tcrb,
  uob: uob,
  kbank: kbank,
  cimb: cimb,
  ghb: ghb,
  ktb: ktb,
}

export default OmiseSVG
